import { companyConfig } from "@/config";

export const sleep = (time: number) => {
    return new Promise(r => setTimeout(r, time))
}

export const raise = (msg: string): never => {
    throw new Error(msg)
}

export function debounce<F extends (...args: any[]) => void>(callback: F, timeout = 500) {
    let timer: ReturnType<typeof setTimeout>;
    return function (...args: Parameters<F>) {
        clearTimeout(timer);
        timer = setTimeout(() => callback(...args), timeout);
    };
}

export function isOutsideWorkingHours(date: string | Date) {
    const d = new Date(date)
    if (d.getDay() === 6 || d.getDay() === 0) { // 6 = Saturday, 0 = Sunday
        return true;
    }
    if (d.getHours() < companyConfig.workingHours.start || d.getHours() > companyConfig.workingHours.end) {
        return true
    }
    return false;
}

export function detectBrowserType(): "chromium" | "safari" | "other" {
    const agent = window.navigator.userAgent.toLowerCase();
    const w = window as any;

    switch (true) {
        case agent.indexOf("edge") > -1: return "other";
        case agent.indexOf("edg/") > -1: return "chromium";
        case agent.indexOf("opr") > -1 && !!w.opr: return "other";
        case agent.indexOf("chrome") > -1 && !!w.chrome: return "chromium";
        case agent.indexOf("trident") > -1: return "other";
        case agent.indexOf("firefox") > -1: return "other";
        case agent.indexOf("safari") > -1: return "safari";
        default: return "other";
    }
}
export function shouldShowExtensionAlert() {
    const browserType = detectBrowserType();
    let shouldShowExtensionAlert = false;
    switch (browserType) {
        case "chromium":
            shouldShowExtensionAlert = true;
            break;
        case "safari":
            shouldShowExtensionAlert = true;
            break;
        default:
            shouldShowExtensionAlert = false;
    }
    return shouldShowExtensionAlert
}
