import { shouldShowExtensionAlert } from '@/utils/utils'
import { CatchBoundary, Outlet, createFileRoute } from '@tanstack/react-router'
import clsx from 'clsx'

export const Route = createFileRoute('/_protected/_tax')({
    component: TaxLayout
})

function TaxLayout() {
    const showingExtensionAlert = shouldShowExtensionAlert()
    return (
        <>
            <div className="lg:hidden bg-white shadow h-16 fixed top-0 w-full z-30"></div>
            <main className={clsx("lg:pt-20 pt-16 flex-1 flex flex-col", showingExtensionAlert && "lg:pt-[107px]")}>
                <div className="flex-1 flex flex-col rounded-lg">
                    <CatchBoundary
                        getResetKey={() => 'reset'}
                        onCatch={(error) => console.error(error)}
                    >
                        <Outlet />
                    </CatchBoundary>
                </div>
            </main>
        </>
    )
}
