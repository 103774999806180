import { Spinner } from "@/components/Spinner";
import { useRequestRecalculationTaxCase } from "@/hooks/useTax";
import { LinkResource } from "@/types/appTypes";
import { ReactFormSubmit } from "@/types/helperTypes";
import { Button, Field, Fieldset, Input, Label, Radio, RadioGroup, Textarea } from "@headlessui/react";
import { ArrowPathIcon, ShieldExclamationIcon } from "@heroicons/react/24/outline";
import { useLoaderData } from "@tanstack/react-router";
import clsx from "clsx";
import { t } from "i18next";

type Props = {
    taxCaseId: string | number;
}

const paymentMethods = {
    clip: "1",
    card: "3",
    savedCard: "4",
} as const;

export default function RequestRecalculation({ taxCaseId }: Props) {
    const recalculate = useRequestRecalculationTaxCase()
    const { user } = useLoaderData({ from: "/_protected" })
    const clipsLeft = user?.credits.left
    const clipPrice = user.prices.clip.tax.recalculate;
    const cardPaymentPrice = user.prices.moneyNoVat.tax.recalculate;
    const hasEnoughClips = (clipsLeft ?? 0) >= clipPrice;
    const isFree = clipPrice === 0 && cardPaymentPrice === 0; // Subscription tier is greather than light

    function onRecalculate(e: ReactFormSubmit) {
        e.preventDefault()
        const data = new FormData(e.currentTarget)
        const method = data.get('paymentMethod')?.toString();
        recalculate.mutate([
            taxCaseId,
            {
                mileage: Number(data.get("mileage")),
                note: data.get("note") as string,
                paymentMethod: method ? Number(method) : null,
            }
        ], {
            onSuccess: (data, input) => {
                if (input[1].paymentMethod === 3 && "uri" in data.data.data) {
                    const paymentLink = data.data.data as LinkResource;
                    window.open(paymentLink.uri, "_self")
                }

            }
        })
    }

    return (
        <Spinner loading={recalculate.isPending}>
            <form onSubmit={onRecalculate}>
                <ShieldExclamationIcon className="mx-auto mb-2 size-16 text-red-600" />
                <h3 className="text-xl text-center text-dark-blue font-semibold">
                    {t("content:The_case_has_expired")}
                </h3>
                <p className="text-sm mt-2 text-center">
                    {t("content:ThePreAssessmentHasExpiredDueToLegalLimits")}
                </p>
                <p className="text-sm mt-1 mb-6 text-center">
                    {t("content:Request_a_recalculation_in_order_to_continue_with_this_case.")}
                </p>
                <Fieldset disabled={recalculate.isSuccess}>
                    <Field className="relative">
                        <Label className="input-label-inset">{t("mileage")}</Label>
                        <Input
                            type="number"
                            name="mileage"
                            required
                            placeholder="54000"
                            className="input-inset"
                        />
                    </Field>
                    <Field className="relative mt-2">
                        <Label className="input-label-inset">{t("remarks")}</Label>
                        <Textarea
                            placeholder="Indsæt dine eventuelle bemærkninger her..."
                            name="note"
                            rows={4}
                            className="input-inset"
                        />
                    </Field>

                    {!isFree && (
                        <Field className="mt-4">
                            <Label className="text-sm font-semibold text-dark-blue">{t("paymentMethods")}</Label>
                            <RadioGroup
                                name="paymentMethod"
                                defaultValue={hasEnoughClips ? paymentMethods.clip : paymentMethods.card}
                                className="mt-3 flex gap-3 max-md:flex-col flex-wrap"
                            >
                                {user.availablePaymentMethods.map(item => {
                                    let title = t("voucher");
                                    let description = `${clipsLeft} ${t("availableClips").toLowerCase()}`
                                    let price = `${clipPrice} ${t("clip", { count: clipPrice })}`;
                                    let disabled = false

                                    if (item.value === paymentMethods.clip) {
                                        disabled = !hasEnoughClips
                                    }

                                    if (item.value === paymentMethods.card || item.value === paymentMethods.savedCard) {
                                        title = item.value === paymentMethods.savedCard
                                            ? `${t("cardPayment")} - ${user.paymentMethodLastFour}`
                                            : t("cardPayment");
                                        description = item.value === paymentMethods.savedCard
                                            ? t("useSavedCard")
                                            : `VISA, Mastercard, Dankort`
                                        price = `Kr. ${cardPaymentPrice},-`;
                                    }
                                    return (
                                        <Field key={item.value} className="flex-1">
                                            <Radio
                                                value={item.value}
                                                disabled={disabled}
                                                className="cursor-pointer block whitespace-nowrap rounded-lg p-3 bg-white border border-dashed group data-[checked]:border-primary data-[disabled]:bg-gray-200"
                                            >
                                                <div className="flex justify-between items-center">
                                                    <Label className="cursor-pointer text-dark-blue text-sm">{title}</Label>
                                                    <span className="flex size-5 items-center justify-center rounded-full border group-data-[checked]:border-primary">
                                                        <span className="invisible size-1 rounded-full bg-primary group-data-[checked]:visible" />
                                                    </span>
                                                </div>
                                                <p className="text-text-secondary text-xs font-medium mt-2">{description}</p>
                                                <p className="text-dark-blue text-sm pt-5">{price}</p>
                                            </Radio>
                                        </Field>
                                    )
                                })}
                            </RadioGroup>
                        </Field>

                    )}
                    <Button type="submit" className="w-full mt-4 btn btn-primary">
                        {t("requestRecalculation")}
                        <ArrowPathIcon className={clsx("size-5", recalculate.isPending && "animate-spin")} aria-hidden="true" />
                    </Button>

                </Fieldset>
            </form>

        </Spinner>
    )
}
