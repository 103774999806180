import NotFound from '@/components/NotFound'
import { shouldShowExtensionAlert } from '@/utils/utils'
import { CatchBoundary, Outlet, createFileRoute, useLoaderData } from '@tanstack/react-router'
import clsx from 'clsx'

export const Route = createFileRoute('/_protected/_equipment')({
    component: CarDataLayout
})

function CarDataLayout() {
    const { user } = useLoaderData({ from: "/_protected" })

    if (!user.products.vehicleReport) {
        return (
            <NotFound />
        )
    }
    const showingExtensionAlert = shouldShowExtensionAlert()
    return (
        <>
            <div className="lg:hidden bg-white shadow h-16 fixed top-0 w-full z-30"></div>
            <main className={clsx("lg:pt-20 pt-16 w-full flex-1 flex flex-col", showingExtensionAlert && "lg:pt-[104px]")}>
                <div className="rounded-lg flex-1 flex flex-col">
                    <CatchBoundary
                        getResetKey={() => 'reset'}
                        onCatch={(error) => console.error(error)}
                    >
                        <Outlet />
                    </CatchBoundary>
                </div>
            </main>

        </>
    )
}

